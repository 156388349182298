import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PortableText from '../portableText'

export default function ClassSchedule() {
  const data = useStaticQuery(graphql`
    {
      allSanitySchedule(sort: { order: ASC, fields: priority }) {
        edges {
          node {
            title
            priority
            _rawBody(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `)
  const allSanitySchedule = (data || {}).allSanitySchedule

  return (
    <div>
      {allSanitySchedule.edges
        .filter((schedule) => schedule.node.priority !== null)
        .map(({ node }) => (
          <div key={node.id}>
            <div className="container justify-content-center px-0">
              <div className="col-12 pt-6 px-0">
                <h1 className="text-pink text-center">{node.title}</h1>
                <p className="lead text-gray text-center">
                  <PortableText blocks={node._rawBody} />
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
