import React from 'react'
import LayoutWhite from '../components/LayoutWhite/index'
import Classes from '../components/Classes/index'

export default function schedule() {
  return (
    <LayoutWhite>
      <section className="p-0 m-0">
        <Classes />
      </section>
    </LayoutWhite>
  )
}
