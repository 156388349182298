import React from 'react'
import './style.scss'
import ClassSchedule from './ClassSchedule'

import { StaticQuery, graphql } from 'gatsby'

const Classes = ({ data }) => {
  return (
    <div>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div key={node.id}>
          <ClassSchedule />

          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-12 pt-6">
                <p className="lead text-gray text-center">
                  {node.frontmatter.promo}
                </p>
                <p className="text-center">
                  <a
                    href="/"
                    className="btn btn-gray btn-rounded align-items-center"
                  >
                    {node.frontmatter.button}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { category: { eq: "schedule" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                category
                title
                button
                promo
                priority
              }
            }
          }
        }
      }
    `}
    render={(data) => <Classes data={data} {...props} />}
  />
)
